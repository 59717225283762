
export default {
  name: "ImageLock",
  props: {
    pending: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submitJoin() {
      this.$emit("join");
    },
  },
};
