
export default {
  name: "WidgetFeaturedCommunity",
  components: {
    photoGallery: () =>
      import("@modoc-component/src.components.photo-gallery/photo-gallery.vue"),
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: {
        join: false,
      },
      drawerMember: false,
    };
  },
  methods: {
    async submitJoinCommunity() {
      this.loading.join = true;
      const formData = new FormData();
      formData.append("id_community", this.data ? this.data.community.id : "");
      await this.$store
        .dispatch("community/postSubmitJoinCommunity", formData)
        .then((res) => {
          this.$parent.fetchData();
          this.loading.join = false;
        })
        .catch((err) => {
          this.loading.join = false;
          throw new Error(err);
        });
    },
    goDetail(item) {
      this.$goCommunity(
        `community/${this.data.community.id}/${item.communities_content_id}`
      );
    },
    goGroup() {
      this.$goCommunity(`community/${this.data.community.id}`);
    },
    notAllowed() {
      this.drawerMember = true;
    },
  },
};
