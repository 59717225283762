
export default {
  components: {
    questionAvatar: () =>
      import("@/components/ask-question/questionAvatar.vue"),
  },
  props: {
    idCommunity: {
      type: [String, Number],
      default: "",
    },
    data: {
      type: Array,
      default: () => [],
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isMember: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showMore: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    userGender: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    loadingJoin: false,
    loadingEdit: false,
    inputComment: "",
    edit_show: "",
    inputEdit: "",
  }),
  computed: {
    dataLength() {
      return this.data.length;
    },
  },
  methods: {
    async submitJoinCommunity(id) {
      this.loadingJoin = true;
      const formData = new FormData();
      formData.append("id_community", id);
      await this.$store
        .dispatch("community/postSubmitJoinCommunity", formData)
        .then((res) => {
          this.$parent.fetchMember();
          this.loadingJoin = false;
        })
        .catch((err) => {
          this.loadingJoin = false;
          throw new Error(err);
        });
    },
    async submitLike(id) {
      const formData = new FormData();
      formData.append("id", id);
      await this.$store
        .dispatch("public/submitDiscussionLike", formData)
        .then(() => {
          this.$message({
            message: "Berhasil menambahkan like",
            type: "success",
          });
          this.$parent.fetchQuestions();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            message: "Gagal menambahkan like ini",
            type: "error",
          });
        });
    },
    async submitAnswer() {
      this.loading = true;
      const formData = new FormData();
      formData.append("id_reference", this.$route.params.qid);
      formData.append("content", this.inputComment);
      await this.$store
        .dispatch("question/submitAnswer", formData)
        .then(() => {
          this.$message({
            message: "Berhasil menambahkan jawaban",
            type: "success",
          });
          this.$emit("success");
          this.inputComment = "";
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            message: "Gagal menambahkan jawaban",
            type: "error",
          });
        });
    },
    handleDropdown(val, item, index) {
      switch (val) {
        case "edit":
          this.editAnswer(item, index);
          break;
        case "delete":
          this.deleteAnswer(item.id);
          break;
      }
    },
    editAnswer(item, index) {
      this.edit_show = index;
      this.inputEdit = item.content;
    },
    async deleteAnswer(id) {
      await this.$store
        .dispatch("question/deleteAnswer", id)
        .then(() => {
          this.$message({
            message: "Berhasil delete jawaban",
            type: "success",
          });
          this.$emit("success");
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            message: "Gagal delete jawaban",
            type: "error",
          });
        });
    },
    async editComment(id) {
      this.loadingEdit = true;
      const formData = new FormData();
      formData.append("content", this.inputEdit);
      const data = {
        id,
        formData,
      };
      await this.$store
        .dispatch("question/editAnswer", data)
        .then(() => {
          this.$emit("success");
          this.inputEdit = "";
          this.edit_show = null;
          this.loadingEdit = false;
          this.$message({
            message: "edit komentar berhasil",
            type: "success",
          });
        })
        .catch((err) => {
          this.loadingEdit = false;
          this.$message({
            message: "edit komentar gagal",
            type: "error",
          });
          throw new Error(err);
        });
    },
  },
};
